import React, {useEffect, useRef} from "react";
import {
	Box,
	Stack,
} from '@mui/material';
import Sender from "./../../components/Sender";
import Reciever from "./../../components/Reciever/Reciever";

const History = (props) => {
	const messagesRef = useRef(null);
	const { records, contact } = props;

	function scrollBottom(behavior = "initial") {
		const scrollObject = { block: "end" };
		if (behavior !== "initial") scrollObject.behavior = behavior;
		setTimeout(() => messagesRef.current?.scrollIntoView(scrollObject), 100);
	}
	useEffect(()=>{
		scrollBottom("initial");
	}, [records]);

	return (
		<Stack spacing={0} className="chat-history" sx={{padding: "20px", maxHeight: "370px", minHeight: "370px", overflowY: "scroll"}}>
			{records && records.map((record, idx) => {
				return (
					<Stack key={"history"+idx} direction="column">
						{record.query && 
                            <Sender 
                            	content={record.query}
                            	contact={contact}
                            />
						}
						{record.reply &&
                            <Reciever
								{...props}
								content={record.reply}
                            />
						}
					</Stack>
				);
			})}
			<Box ref={messagesRef} sx={{paddingTop: "20px"}}/>
		</Stack>
	);
};

export default History;