function formatTimeAgo(dateString) {
    const now = new Date();
    const givenDate = new Date(dateString);
    const diffInMilliseconds = now - givenDate;
  
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
  
    if (diffInDays >= 1) {
      // Return days ago if the difference is 1 or more days
      const days = Math.floor(diffInDays);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (diffInHours >= 1) {
      // Return hours and minutes ago if within the last 24 hours
      const hours = Math.floor(diffInHours);
      const minutes = Math.floor(diffInMinutes % 60);
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diffInMinutes >= 1) {
      // Return minutes ago if within the last 60 minutes
      const minutes = Math.floor(diffInMinutes);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else {
      // Return "Just now" if within the last minute
      return 'Just now';
    }
}
const trimString = (str, len) => {
    try{
      if (str.length <= len) {
        return str;
      }
      return str.slice(0, len) + '...';
    }catch(error){
      console.error("@@ Error: trimString @@", error);
      return ""
    }
    
}
  export {
    formatTimeAgo,
    trimString
  }