export const WEB_WIDGET_BUTTON_LOGO_SVG = (
	<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M26.7695 19.6778C25.5363 17.9054 23.4944 16.7468 21.184 16.7468C17.4185 16.7468 9.49905 23.6208 9.49905 23.6208C9.49905 23.6208 9.50758 23.6282 9.52422 23.6426L9.49905 23.6426C9.49905 23.6426 8.11038 24.848 6.57031 25.8499C8.72379 27.2133 11.2711 28.0016 14.0009 28.0016C19.6833 28.0016 24.575 24.5857 26.7695 19.6778Z" fill="url(#paint0_linear_1699_34435)" />
		<path d="M26.7731 19.6765C27.5327 17.9778 27.9691 16.1003 28.0033 14.1238C27.9103 10.409 24.8944 7.42645 21.1876 7.42645C17.4221 7.42645 9.50265 14.3005 9.50265 14.3005C9.50265 14.3005 9.51118 14.3079 9.52782 14.3222L9.50265 14.3222C9.50265 14.3222 5.43689 17.8516 3.50376 17.8516C1.72063 17.8516 0.248903 16.5071 0.03125 14.7687C0.321326 19.4419 2.86282 23.4992 6.57391 25.8486C8.11398 24.8467 9.50265 23.6412 9.50265 23.6412L9.52782 23.6412C9.51118 23.6269 9.50265 23.6195 9.50265 23.6195C9.50265 23.6195 17.4221 16.7454 21.1876 16.7454C23.498 16.7454 25.54 17.9041 26.7731 19.6765Z" fill="url(#paint1_linear_1699_34435)" />
		<path d="M9.49914 13.8584C9.49914 13.8584 17.4186 6.98431 21.1841 6.98431C24.9495 6.98431 28.002 10.0619 28.002 13.8584C28.002 13.8656 28.002 13.8729 28.002 13.8801H28.002C28.002 13.9644 28.0013 14.0486 27.9998 14.1326C27.9072 10.4175 24.8911 7.43451 21.1841 7.43451C17.4186 7.43451 9.49914 14.3086 9.49914 14.3086C9.49914 14.3086 9.50767 14.316 9.5243 14.3303L9.49914 14.3303C9.49914 14.3303 5.43338 17.8596 3.50025 17.8596C1.71711 17.8596 0.245394 16.5152 0.0277432 14.7768C0.00933984 14.4803 0 14.1813 0 13.8801C0 15.8293 1.56712 17.4094 3.50025 17.4094C5.43338 17.4094 9.49914 13.8801 9.49914 13.8801L9.5243 13.8801C9.50767 13.8658 9.49914 13.8584 9.49914 13.8584Z" fill="url(#paint2_linear_1699_34435)" />
		<path d="M1.38676 8.32374C2.6199 10.0962 4.66189 11.2548 6.97229 11.2548C10.7377 11.2548 18.6572 4.38075 18.6572 4.38075C18.6572 4.38075 18.6487 4.37335 18.632 4.35903L18.6572 4.35903C18.6572 4.35903 20.0459 3.15358 21.5859 2.15166C19.4325 0.788318 16.8851 -4.22863e-06 14.1553 -4.469e-06C8.47297 -4.96936e-06 3.58124 3.41588 1.38676 8.32374Z" fill="url(#paint3_linear_1699_34435)" />
		<path d="M9.49789 13.8682C9.49789 13.8682 5.43267 17.4022 3.49979 17.4022C1.56691 17.4022 0 15.82 0 13.8682C0 11.9164 1.56691 10.3342 3.49979 10.3342C5.43267 10.3342 9.49789 13.8682 9.49789 13.8682Z" fill="url(#paint4_linear_1699_34435)" />
		<defs>
			<linearGradient id="paint0_linear_1699_34435" x1="29.8344" y1="15.9364" x2="6.07778" y2="20.6776" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="1" stopColor="white" />
			</linearGradient>
			<linearGradient id="paint1_linear_1699_34435" x1="-6.61149" y1="24.0386" x2="28.5143" y2="21.9286" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="1" stopColor="white" />
			</linearGradient>
			<linearGradient id="paint2_linear_1699_34435" x1="-0.268175" y1="17.6201" x2="29.154" y2="1.82832" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="1" stopColor="white" />
			</linearGradient>
			<linearGradient id="paint3_linear_1699_34435" x1="2.71646" y1="8.33982" x2="19.7366" y2="0.0966893" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="1" stopColor="white" />
			</linearGradient>
			<linearGradient id="paint4_linear_1699_34435" x1="17.3755" y1="14.4759" x2="0.998653" y2="10.1166" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0" />
				<stop offset="0.915" stopColor="white" />
			</linearGradient>
		</defs>
	</svg>);

export const SPARKLE_ICON = (
	<svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1699_34394)">
			<path d="M15.4711 7.8488L17.0498 13.0265C17.4768 14.427 18.573 15.5231 19.9735 15.9502L25.1512 17.5289C25.6163 17.6707 25.6163 18.3292 25.1512 18.471L19.9735 20.0498C18.573 20.4768 17.4768 21.5729 17.0498 22.9735L15.4711 28.1511C15.3292 28.6162 14.6708 28.6162 14.5289 28.1511L12.9502 22.9735C12.5232 21.5729 11.427 20.4768 10.0265 20.0498L4.84884 18.471C4.38372 18.3292 4.38372 17.6707 4.84884 17.5289L10.0265 15.9502C11.427 15.5231 12.5232 14.427 12.9502 13.0265L14.5289 7.84881C14.6708 7.38369 15.3292 7.38369 15.4711 7.8488Z" fill="#6257FF" />
			<path d="M25.7019 7.64947L26.3785 9.86847C26.5615 10.4687 27.0313 10.9385 27.6315 11.1215L29.8505 11.7981C30.0498 11.8589 30.0498 12.1411 29.8505 12.2019L27.6315 12.8784C27.0313 13.0615 26.5615 13.5312 26.3785 14.1315L25.7019 16.3505C25.6411 16.5498 25.3589 16.5498 25.2981 16.3505L24.6215 14.1315C24.4385 13.5312 23.9687 13.0615 23.3685 12.8784L21.1495 12.2019C20.9502 12.1411 20.9502 11.8589 21.1495 11.7981L23.3685 11.1215C23.9687 10.9385 24.4385 10.4687 24.6215 9.86847L25.2981 7.64947C25.3589 7.45014 25.6411 7.45014 25.7019 7.64947Z" fill="#6257FF" />
			<path d="M25.7019 19.6495L26.3785 21.8685C26.5615 22.4687 27.0313 22.9385 27.6315 23.1215L29.8505 23.7981C30.0498 23.8589 30.0498 24.1411 29.8505 24.2019L27.6315 24.8784C27.0313 25.0615 26.5615 25.5312 26.3785 26.1315L25.7019 28.3505C25.6411 28.5498 25.3589 28.5498 25.2981 28.3505L24.6215 26.1315C24.4385 25.5312 23.9687 25.0615 23.3685 24.8784L21.1495 24.2019C20.9502 24.1411 20.9502 23.8589 21.1495 23.7981L23.3685 23.1215C23.9687 22.9385 24.4385 22.4687 24.6215 21.8685L25.2981 19.6495C25.3589 19.4501 25.6411 19.4501 25.7019 19.6495Z" fill="#6257FF" />
		</g>
		<defs>
			<clipPath id="clip0_1699_34394">
				<rect width="27" height="27" fill="white" transform="translate(4.5 4.5)" />
			</clipPath>
		</defs>
	</svg>
);

export const HOME_ICON = (color) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M20.5127 9.7219L13.0127 2.90627C12.7362 2.6533 12.375 2.513 12.0002 2.513C11.6254 2.513 11.2642 2.6533 10.9877 2.90627L3.4877 9.7219C3.33414 9.86238 3.2115 10.0333 3.12757 10.2237C3.04364 10.4142 3.00027 10.62 3.0002 10.8281V19.4625C2.9941 19.8407 3.12761 20.2078 3.3752 20.4938C3.51572 20.6536 3.68882 20.7814 3.88287 20.8687C4.07692 20.9561 4.28741 21.0008 4.5002 21H9.0002C9.19911 21 9.38988 20.921 9.53053 20.7804C9.67118 20.6397 9.7502 20.4489 9.7502 20.25V15.75C9.7502 15.5511 9.82922 15.3603 9.96987 15.2197C10.1105 15.079 10.3013 15 10.5002 15H13.5002C13.6991 15 13.8899 15.079 14.0305 15.2197C14.1712 15.3603 14.2502 15.5511 14.2502 15.75V20.25C14.2502 20.4489 14.3292 20.6397 14.4699 20.7804C14.6105 20.921 14.8013 21 15.0002 21H19.5002C19.749 21.0022 19.9942 20.9409 20.2127 20.8219C20.4504 20.6924 20.649 20.5014 20.7875 20.2688C20.9261 20.0363 20.9995 19.7707 21.0002 19.5V10.8281C21.0001 10.62 20.9568 10.4142 20.8728 10.2237C20.7889 10.0333 20.6663 9.86238 20.5127 9.7219Z" fill={color} />
	</svg>);

export const CHAT_ICON = (color) => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M10.3065 21.6037C10.3065 21.6037 8.71523 21.3246 7.30687 20.5511L4.49705 21.3466C4.49705 21.3466 4.11119 21.4595 3.71542 21.3583C3.71542 21.3583 3.31966 21.257 3.0308 20.9682C3.0308 20.9682 2.74194 20.6793 2.6407 20.2836C2.6407 20.2836 2.53947 19.8878 2.65414 19.4957L3.44791 16.6921C3.44791 16.6921 2.67435 15.2838 2.39523 13.6925C2.39523 13.6925 2.08778 11.9397 2.41995 10.1914C2.41995 10.1914 2.75213 8.44309 3.68103 6.92519C3.68103 6.92519 4.60993 5.4073 6.01553 4.3159C6.01553 4.3159 7.42113 3.22451 9.12184 2.70062C9.12184 2.70062 10.8225 2.17674 12.5986 2.28804C12.5986 2.28804 14.3747 2.39935 15.9967 3.13147C15.9967 3.13147 17.6187 3.86359 18.877 5.12193C18.877 5.12193 20.1354 6.38027 20.8675 8.00227C20.8675 8.00227 21.5996 9.62426 21.7109 11.4003C21.7109 11.4003 21.8222 13.1764 21.2983 14.8771C21.2983 14.8771 20.7745 16.5778 19.6831 17.9834C19.6831 17.9834 18.5917 19.389 17.0738 20.3179C17.0738 20.3179 15.5559 21.2468 13.8076 21.579C13.8076 21.579 12.0593 21.9112 10.3065 21.6037ZM13.5276 20.1054C13.5276 20.1054 10.4676 20.6868 7.78817 19.0989C7.61134 18.9941 7.39871 18.9661 7.20092 19.0221L4.09843 19.9005L4.97687 16.7981C5.03287 16.6003 5.00523 16.3882 4.90042 16.2113C4.90042 16.2113 3.31213 13.5316 3.89359 10.4714C3.89359 10.4714 4.47505 7.41111 6.93547 5.50069C6.93547 5.50069 9.39589 3.59027 12.5048 3.78511C12.5048 3.78511 15.6137 3.97994 17.8164 6.18259C17.8164 6.18259 20.019 8.38524 20.2139 11.4942C20.2139 11.4942 20.4087 14.6031 18.4983 17.0635C18.4983 17.0635 16.5879 19.5239 13.5276 20.1054Z" fill={color} />
	<path d="M8.99901 11.25H14.999C15.4132 11.25 15.749 10.9142 15.749 10.5C15.749 10.0858 15.4132 9.75 14.999 9.75H8.99901C8.58479 9.75 8.24901 10.0858 8.24901 10.5C8.24901 10.9142 8.58479 11.25 8.99901 11.25Z" fill="#646464" />
	<path d="M8.99901 14.25H14.999C15.4132 14.25 15.749 13.9142 15.749 13.5C15.749 13.0858 15.4132 12.75 14.999 12.75H8.99901C8.58479 12.75 8.24901 13.0858 8.24901 13.5C8.24901 13.9142 8.58479 14.25 8.99901 14.25Z" fill="#646464" />
</svg>);

export const INTELSWIFT_GRAY_LOGO = (<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19.6223 14.0257C18.7415 12.7689 17.2831 11.9473 15.6329 11.9473C12.9435 11.9473 7.28712 16.8217 7.28712 16.8217C7.28712 16.8217 7.29321 16.8269 7.30509 16.8371L7.28712 16.8371C7.28712 16.8371 6.29528 17.6919 5.19531 18.4024C6.7334 19.3691 8.55279 19.9281 10.5025 19.9281C14.561 19.9281 18.0549 17.5059 19.6223 14.0257Z" fill="url(#paint0_linear_1699_33671)" />
	<path d="M19.6234 14.0258C20.1659 12.8212 20.4776 11.4899 20.502 10.0884C20.4356 7.4542 18.2816 5.33923 15.634 5.33923C12.9446 5.33923 7.28824 10.2137 7.28824 10.2137C7.28824 10.2137 7.29433 10.2189 7.30621 10.2291L7.28824 10.2291C7.28824 10.2291 4.38434 12.7317 3.00363 12.7317C1.73005 12.7317 0.678893 11.7784 0.523438 10.5457C0.73062 13.8595 2.54584 16.7365 5.19643 18.4025C6.2964 17.692 7.28824 16.8372 7.28824 16.8372L7.30621 16.8372C7.29433 16.8271 7.28824 16.8218 7.28824 16.8218C7.28824 16.8218 12.9446 11.9474 15.634 11.9474C17.2842 11.9474 18.7426 12.769 19.6234 14.0258Z" fill="url(#paint1_linear_1699_33671)" />
	<path d="M7.28461 9.89433C7.28461 9.89433 12.941 5.0199 15.6304 5.0199C18.3198 5.0199 20.5 7.20226 20.5 9.89433C20.5 9.89947 20.5 9.9046 20.5 9.90973H20.5C20.5 9.96954 20.4995 10.0292 20.4984 10.0888C20.4323 7.45437 18.2781 5.33913 15.6304 5.33913C12.941 5.33913 7.28461 10.2136 7.28461 10.2136C7.28461 10.2136 7.29071 10.2188 7.30258 10.229L7.28462 10.229C7.28462 10.229 4.38071 12.7316 3 12.7316C1.72642 12.7316 0.675269 11.7783 0.519815 10.5456C0.506671 10.3353 0.5 10.1233 0.5 9.90973C0.5 11.2919 1.61929 12.4124 3 12.4124C4.38071 12.4124 7.28462 9.90973 7.28462 9.90973L7.30258 9.90973C7.29071 9.89958 7.28461 9.89433 7.28461 9.89433Z" fill="url(#paint2_linear_1699_33671)" />
	<path d="M1.48711 5.97417C2.36786 7.23101 3.82632 8.05261 5.47649 8.05261C8.1659 8.05261 13.8223 3.17818 13.8223 3.17818C13.8223 3.17818 13.8162 3.17293 13.8043 3.16278L13.8223 3.16278C13.8223 3.16278 14.8141 2.30798 15.9141 1.59752C14.376 0.630769 12.5566 0.0717656 10.6069 0.0717654C6.54833 0.0717651 3.05448 2.49399 1.48711 5.97417Z" fill="url(#paint3_linear_1699_33671)" />
	<path d="M7.28372 9.90418C7.28372 9.90418 4.3802 12.4102 2.99967 12.4102C1.61914 12.4102 0.5 11.2882 0.5 9.90418C0.5 8.52016 1.61914 7.39819 2.99967 7.39819C4.3802 7.39819 7.28372 9.90418 7.28372 9.90418Z" fill="url(#paint4_linear_1699_33671)" />
	<defs>
		<linearGradient id="paint0_linear_1699_33671" x1="21.8114" y1="11.3726" x2="4.85296" y2="14.7815" gradientUnits="userSpaceOnUse">
			<stop stopColor="white" stopOpacity="0" />
			<stop offset="1" stopColor="#646464" />
		</linearGradient>
		<linearGradient id="paint1_linear_1699_33671" x1="-4.22104" y1="17.119" x2="20.8657" y2="15.6011" gradientUnits="userSpaceOnUse">
			<stop stopColor="white" stopOpacity="0" />
			<stop offset="1" stopColor="#646464" />
		</linearGradient>
		<linearGradient id="paint2_linear_1699_33671" x1="0.30846" y1="12.5618" x2="21.2548" y2="1.23792" gradientUnits="userSpaceOnUse">
			<stop stopColor="white" stopOpacity="0" />
			<stop offset="1" stopColor="#646464" />
		</linearGradient>
		<linearGradient id="paint3_linear_1699_33671" x1="2.43683" y1="5.98557" x2="14.5597" y2="0.0717639" gradientUnits="userSpaceOnUse">
			<stop stopColor="white" stopOpacity="0" />
			<stop offset="1" stopColor="#646464" />
		</linearGradient>
		<linearGradient id="paint4_linear_1699_33671" x1="12.9102" y1="10.3351" x2="1.2245" y2="7.202" gradientUnits="userSpaceOnUse">
			<stop stopColor="white" stopOpacity="0" />
			<stop offset="0.915" stopColor="#646464" />
		</linearGradient>
	</defs>
</svg>
);