import * as React from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Button,
	Stack,
	Typography,
} from "@mui/material"

import useSettingsSelector from "./../../store/selectors/settings";

const Gallery = (props) => {

	const { RECIEVER } = useSettingsSelector();
	const {content} = props;

	const GalleryItem = ({item, idx}) => {
		return (
			<Card sx={{ width: "100%", backgroundColor: RECIEVER.BACKGROUND_COLOR, borderRadius: "12px" }}>
				<CardContent>
					<Typography variant="body2" color="text.secondary">
						{item.description}
					</Typography>
				</CardContent>
				<CardMedia
					sx={{ 
						height: 140,
						width: "90%",
    					margin: "0 auto"
					}}
					image={item.image}
					title="gallery item"
				/>
				<CardActions>
					<Button
						component={Link}
						to={item.buttonURL}
						target="_blank"
						size="small"
						variant="outlined"
						sx={{
							borderColor: "lightgrey", 
							color: "black",
							borderRadius: "12px",
							width: "90%",
							margin: "0 auto"
						}}
					>
						{item.buttonName}
					</Button>
				</CardActions>
			</Card>
		);
	};
	return (
		<>
			<Stack direction="column" spacing={1} sx={{width: "80%", paddingBottom: "10px", overflowX: "scroll"}}>
				{content.items.map((item, idx) => {
					return (
						<Box key={`gallery-item-${idx}`}>
							<GalleryItem item={item} idx={idx}/>
						</Box>
					)
				})}
			</Stack>
		</>
	);
};

export default Gallery;