import { createContext, useState } from "react";
import { DEFAULT_THEME } from "../../constants/settings";

const initialThemeSettingsState = {
	HEADER: {
		FONT_COLOR: DEFAULT_THEME.HEADER.FONT_COLOR,
		BACKGROUND_COLOR: DEFAULT_THEME.HEADER.BACKGROUND_COLOR,
	},
	CONTENT: {
		FONT_COLOR: DEFAULT_THEME.CONTENT.FONT_COLOR,
		BACKGROUND_COLOR: DEFAULT_THEME.CONTENT.BACKGROUND_COLOR,
	},
	WEB_WIDGET_BUTTON: {
		FONT_COLOR: DEFAULT_THEME.WEB_WIDGET_BUTTON.FONT_COLOR,
		BACKGROUND_COLOR: DEFAULT_THEME.WEB_WIDGET_BUTTON.BACKGROUND_COLOR,
	},
	FOOTER: {
		FONT_COLOR: DEFAULT_THEME.FOOTER.FONT_COLOR,
		BACKGROUND_COLOR: DEFAULT_THEME.FOOTER.BACKGROUND_COLOR,
		FONT_UN_SELECTED_COLOR: DEFAULT_THEME.FOOTER.FONT_UN_SELECTED_COLOR,
	},
	SUB_FOOTER: {
		FONT_COLOR: DEFAULT_THEME.SUB_FOOTER.FONT_COLOR,
		BACKGROUND_COLOR: DEFAULT_THEME.SUB_FOOTER.BACKGROUND_COLOR,
	},
	HOME: {
		LIST_BUTTON_COLOR: DEFAULT_THEME.HOME.LIST_BUTTON_COLOR
	},
	CHAT: {
		BUTTON_BACKGROUND_COLOR: DEFAULT_THEME.CHAT.BUTTON_BACKGROUND_COLOR
	},
	SENDER: {
		FONT_COLOR: DEFAULT_THEME.SENDER.FONT_COLOR,
		BACKGROUND_COLOR: DEFAULT_THEME.SENDER.BACKGROUND_COLOR,
		AVATAR_BACKGROUND_COLOR: DEFAULT_THEME.SENDER.AVATAR_BACKGROUND_COLOR,
	},
	RECIEVER: {
		FONT_COLOR: DEFAULT_THEME.RECIEVER.FONT_COLOR,
		BACKGROUND_COLOR: DEFAULT_THEME.RECIEVER.BACKGROUND_COLOR,
		AVATAR_BACKGROUND_COLOR: DEFAULT_THEME.RECIEVER.AVATAR_BACKGROUND_COLOR,
	}
};

const initialState = {
	...initialThemeSettingsState,
	isThemeLoading: false,
	error: undefined,

};
const SettingsContext = createContext(initialState);

const SettingsContextProvider = (props) => {
	const {
		children
	} = props;

	const [theme, setTheme] = useState({ ...initialThemeSettingsState });
	const [isThemeLoading, setIsThemeLoading] = useState(false);
	const [error, setError] = useState(undefined);

	const value = {
		...theme,
		isThemeLoading,
		error
	};
	return (
		<SettingsContext.Provider value={value}>
			{children}
		</SettingsContext.Provider>
	);
};

export {
	SettingsContext,
	SettingsContextProvider
};