import React, {useState, useEffect, useRef, memo} from "react";
import axios from "axios";
import { get } from "lodash";
import { BiSend } from "react-icons/bi";
import {
	Box,
	Stack,
	FormControl,
	InputAdornment,
	OutlinedInput,
	IconButton
} from '@mui/material';

import History from "./history";
import eventEmitter from "./../../components/EventEmitter"
import { INTELSWIFT_GRAY_LOGO } from './../../constants';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });

const Chat = (props) => {
	const {conversations, pageProps, botId, uuid, lang, tenantId, widget} = props;
	const locales = get(widget, "meta.locale", []);
	const sendMsgLocale = locales.find(el => el.field === "send_msg")
	
	const pagePropsRef = useRef(null);

	const [query, setQuery] = useState("");
	const [lastQuery, setLastQuery] = useState("");
    const [records, setRecords] = useState([])
	const [initialized, setInitialized] = useState(false);
	const [assistantTyping, setAssistantTyping] = useState(false);
	const [isLastRecordCollector, setIsLastRecordCollector] = useState(false);
	
	
	const handleRequest = async (content) => {
        //TODO: this call should be verified in UCS
		await axiosInstance.post(`/widgets/query`, {
			content: content,
			tenantId: tenantId,
			botId: botId,
			contactId: uuid,
			lang: lang,
			href: window.location.hostname 
		});
	};
	const handleSubmit = (msg) => {
		handleRequest({
			conversationId: pageProps.conversationId,
			msg_query: {
				text: query || msg
			}
		});
		setLastQuery(query || msg);
		setQuery("");
	};

	const initSessionAndGetContact = async (records) => {
		if(records.length === 0){
			await handleRequest({
				conversationId: pageProps.conversationId,
				new_session: true
			});
		}
	};
	const handleAssistantTyping = (message) => {
		const {conversationId} = message;
		if(pagePropsRef.current?.conversationId === conversationId){
			setAssistantTyping(true);
		}
	}
	useEffect(()=>{
		if(Array.isArray(records)){
			if(records.length > 0){
				const lastRecordReply = records[records.length -1].reply;
				if(lastRecordReply){
					setAssistantTyping(false);
					if(lastRecordReply.type === 'data_collector' || lastRecordReply.type === 'contact_data_collector' || lastRecordReply.type === 'ticket'){
						setIsLastRecordCollector(true)
					}else{
						setIsLastRecordCollector(false)
					}
				}
			}
		}
	}, [records])
	
	useEffect(()=>{
		if(conversations && pageProps){
			if(pageProps.conversationId){
				const conversation = conversations.find(conv => conv._id === pageProps.conversationId)
				const _records = conversation?.records || []
				setRecords(_records)
				if(conversation?.placeholder === true && !initialized){
					initSessionAndGetContact(_records)
					setInitialized(true);
				}
			}else{
				const conversation = conversations.find(conv => conv.placeholder === true)
				const _records = conversation?.records || []
				setRecords(_records)
				if(!initialized){
					initSessionAndGetContact(_records)
					setInitialized(true);
				}
			}
		}
	}, [pageProps, conversations])
	
	useEffect(()=>{
		if(pageProps){
			pagePropsRef.current = pageProps;
			if(pageProps.article){
				handleSubmit(pageProps.article)
			}
		}
	}, [pageProps]);

	useEffect(() => {
        eventEmitter.on('assistant_typing', handleAssistantTyping);
        // Cleanup listener on unmount
        return () => {
			eventEmitter.off('assistant_typing', handleAssistantTyping);
        };
    }, []);
	
	return (
		<Box sx={{height: "100%", margin: "0px", overflowY: "hidden"}}>
			<Stack sx={{height: "100%", justifyContent: "space-between"}}>
				<History
					{...props}
					locales={locales}
					records={records}
					query={lastQuery}
					setLastQuery={setLastQuery}
					handleRequest={handleRequest}
				/>
				{assistantTyping && (
					<Stack direction="row" spacing={1} sx={{padding: "10px 21px"}}>
						<>{INTELSWIFT_GRAY_LOGO}</>
						<Box className="typing-indicator">
							<div className="dot"></div>
							<div className="dot"></div>
							<div className="dot"></div>
						</Box>
					</Stack>
				)}
				<FormControl
					variant="outlined"
					fullWidth
					size="small"
					className="chat-input"
				>
					<OutlinedInput
						onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
						onChange={(e) => { setQuery(e.target.value); }}
						value={query}
						autoFocus={true}
						disabled={isLastRecordCollector}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="open chat screen"
									edge="end"
									color="primary"
									disabled={query.trim() === ""}
									onClick={handleSubmit}
								>
									<BiSend />
								</IconButton>
							</InputAdornment>
						}
						placeholder={sendMsgLocale && sendMsgLocale.name ? sendMsgLocale.name : "Ask me anything"}
						sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", height: "48px" }}
					/>
				</FormControl>
			</Stack>
		</Box>
	);
};

export default memo(Chat);