import React, { useState, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ChannelButtons from "../pages/WidgetButton";

const PublicRoutes = (props) => {
	return (
		<Routes>
			<Route path="/widget-button" element={<ChannelButtons {...props}/>}></Route>
			<Route
				path="*"
				element={
					<div>
						<h2>404 Page not found</h2>
					</div>
				}
			/>
		</Routes>
	);
};

export default PublicRoutes;