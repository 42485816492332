import React from "react";
import { styled } from "@mui/system";
import {
	Avatar,
	Typography,
	Stack
} from "@mui/material";

const Avatars = styled("div")({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	"& > *:not(:first-child)": {
		marginLeft: -15
	},
});

// Custom styled avatar
const StyledAvatar = styled(Avatar)({
	border: "2px solid white", // Add a white border
	objectFit: "cover",
	height: "36px",
	width: "36px",

});

// Custom styled extra agents avatars
const MoreAvatar = styled(Avatar)({
	border: "2px solid white",
	backgroundColor: "rgb(233, 231, 255)",
	color: "black",
});


const AvatarStack = ({agents, displayAgentInfo}) => {
	const renderAvatars = () => {
		return agents.slice(0, 4).map((agent, index) => {
			// If the fourth avatar is being rendered and there are more than 3 avatars, display the extra count
			if (index === 3 && agents.length > 3) {
				return <MoreAvatar key={index}>+{agents.length - 3}</MoreAvatar>;
			}
			return (
				<StyledAvatar
					key={index}
					alt={agent.name || "Agent"}
					src={agent.profileURL || ""}
				>
					{agent.profileURL ? null : agent.name ? agent.name.charAt(0) : "A"}
				</StyledAvatar>
			)
		});
	};
	
	return (
		<>
			{agents && agents.length === 1 && (
				<Stack direction="row" spacing={1}>
					<StyledAvatar
						alt={agents[0].name || "Agent"}
						src={agents[0].profileURL || ""}
					/>
					{displayAgentInfo && (
						<Stack direction="column" spacing={0}>
							<Typography variant="body1" sx={{color: "black"}}>{agents[0].name}</Typography>
							<Typography variant="body1" sx={{color: "grey"}}>{agents[0].presence === 1 ? "Online" : "Offline"}</Typography>
						</Stack>
					)}
				</Stack>
			)}
			{agents && agents.length > 1 && (
				<Avatars>
					{renderAvatars(agents)}
				</Avatars>
			)}
		</>
		
	);
};
export default AvatarStack;