export const DEFAULT_THEME = {
	HEADER: {
		FONT_COLOR: 'white',
		BACKGROUND_COLOR: '#E9E7FF',
	},
	CONTENT: {
		FONT_COLOR: '',
		BACKGROUND_COLOR: '#FAFAFA',
	},
	WEB_WIDGET_BUTTON: {
		FONT_COLOR: '',
		BACKGROUND_COLOR: '#6257FF',        
	},
	FOOTER: {
		FONT_COLOR: '#6257FF',
		FONT_UN_SELECTED_COLOR: '#646464',
		BACKGROUND_COLOR: '#FAFAFA',        
	},
	SUB_FOOTER: {
		FONT_COLOR: '#6257FF',
		BACKGROUND_COLOR: '#FAFAFA',        
	},
	HOME: {
		LIST_BUTTON_COLOR: '#6257FF'
	},
	CHAT: {
		BUTTON_BACKGROUND_COLOR: '#6257FF'
	},
	SENDER: {
		FONT_COLOR: 'white',
		BACKGROUND_COLOR: '#344054',
		AVATAR_BACKGROUND_COLOR: ''
	},
	RECIEVER: {
		FONT_COLOR: '',
		BACKGROUND_COLOR: '#F5F8FD',
		AVATAR_BACKGROUND_COLOR: '#E7E5FF'
	}    
};