import React, {useState} from "react";
import { styled } from "@mui/system";
import {
	Box,
	Stack,
	Avatar,
	Typography,
} from "@mui/material";
import { Message, MessageAndButtons, Gallery, DataCollector, ContactDataCollector, Ticket } from "./../../components/widgets";
import { INTELSWIFT_GRAY_LOGO } from '../../constants';

const AssistantAvatar = styled(Avatar)({
	backgroundColor: "white",
	height: "21px",
	width: "21px",
});

export default function Reciever(props) {
	let ReplyContent = null;

	const {content, agents} = props;
	const {agentIDaasId, type} = content;
	const [hidden, setHidden] = useState(false);

	const getAssistant = (agentIDaasId) => {
		const suffix = "AI agent"
		const name = agentIDaasId.substring("ai_assistant_".length) + ` (${suffix})`; 
		return (
			<Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
				<AssistantAvatar >{INTELSWIFT_GRAY_LOGO}</AssistantAvatar>
				<Typography variant="body1">{name}</Typography>
			</Stack>
		)
	}
	const getAgent = (agentIDaasId) => {
		const agent = agents.find(el => el.agentIDaasId === agentIDaasId);
		if(agent){
			const {name, profileURL} = agent;
			return (
				<Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
					<Typography variant="body1">{name}</Typography>
				</Stack>
			)
		}else{
			return ""
		}
	}
	switch (type) {
		case 'msg_and_buttons':
			ReplyContent = <MessageAndButtons {...props} />;
			break;
		case 'msg':
			ReplyContent = <Message {...props} />;
			break;
		case 'assistant':
			ReplyContent = <Message {...props} />;
			break;
		case 'gallery':
			ReplyContent = <Gallery {...props} />;
			break;
		case 'ticket':
			ReplyContent = <Ticket {...props} setHidden={setHidden}/>;
			break;
		case 'contact_data_collector':
			ReplyContent = <ContactDataCollector {...props} setHidden={setHidden}/>;
			break;
		case 'data_collector':
			ReplyContent = <DataCollector {...props} setHidden={setHidden}/>;
			break;   
		default:
			console.warn(`Unrecognized type ${type} of receiver content.`);
	}

	return (
		<>
			{content && !hidden && (
				<Stack className="reciever-ui" direction="column" spacing={1}>
					<Stack className="receiver-info">
						{agentIDaasId && agentIDaasId !== 'assistant' && (
							<>{getAgent(agentIDaasId)}</>
						)}
						{agentIDaasId && agentIDaasId.includes('ai_assistant') && (
							<>{getAssistant(agentIDaasId)}</>
						)}
						
					</Stack>
					<Box className="reciever-message-container" sx={{width: type === "collector" || type === "ticket" || type === "gallery" ? "100% !important" : "fit-content"}}>
						{ReplyContent}
					</Box>
				</Stack>
			)}
		</>
	);
}
