import React, { useState, useEffect, useRef } from "react";
import getBrowserFingerprint from "get-browser-fingerprint";
import axios from "axios";
import { get } from "lodash";
import ProgressBar from "./../components/ProgressBar";
import UnVerifiedPage from "../pages/Unverified";
import SocketClient from "./../socket-client";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });
const uuid = getBrowserFingerprint();

const ProtectedRoute = (props) => {

	const { botId, tenantId, host, lang } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [isVerified, setIsVerified] = useState(false);
	const [widget, setWidget] = useState(null);
	const [initializedSocket, setInitializedSocket] = useState(false);
	const socketRef = useRef(null);

	const fetchWidget = async (botId, tenantId, lang) => {
		const response = await axiosInstance.get(`/widgets/${botId}?tenantId=${tenantId}&lang=${lang}`);
		if(response.status === 200){
			const _widget = get(response, "data.data", null);
			if(_widget){
				setWidget(_widget);
				setIsLoading(false);
				_widget.domains.forEach(el => {
					const domain = el.domain;
					if(domain.includes(host)){
						setIsVerified(true);
					}
				});
			}
		}
	};

	useEffect(() => {
		if (tenantId && botId && lang) {
			fetchWidget(botId, tenantId, lang);
		}
	}, [tenantId, botId, lang]);

	useEffect(()=>{
		if(uuid && tenantId && botId && !initializedSocket){
			socketRef.current = SocketClient.getInstance(tenantId, uuid, botId);
			setInitializedSocket(true)
		}
	}, [uuid, tenantId, botId]);
	
	if (isLoading) {
		return (
			<ProgressBar />
		);
	}

	const bootstrapChildren = () => {
		return React.cloneElement(props.children, {
			widget: widget,
			uuid: uuid
		});    
	};
	return (
		<>
			{!isLoading && widget && uuid && (
				<>
					{isVerified && (
						<React.Fragment>
							{bootstrapChildren()}
						</React.Fragment>
					)}
					{!isVerified && (
						<UnVerifiedPage />
					)}
				</>
			)}
		</>
	);
};
export default ProtectedRoute;
