import { createTheme } from "@mui/material/styles";

export const DefaultTheme = createTheme({
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-notchedOutline": {
						border: `1px solid #BBB4FF`,
					},
					"&.Mui-focused": {
						"& .MuiOutlinedInput-notchedOutline": {
							border: `2px solid #BBB4FF`,
						},
					}
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				outlined: {
					'&.MuiInputLabel-shrink': {
						color: "black"
					},
				},
			},
		}
	}
});
