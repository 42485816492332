import React, {useEffect, useState} from "react";
import { BiSend } from "react-icons/bi";
import { get } from "lodash";
import { 
	IconButton,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
} from "@mui/material";

import TextMessage from "./TextMessage"

const Ticket = (props) => {
	const {content, pageProps, handleRequest, setHidden} = props;
    const {action} = content;
	const [innerAttrs, setInnerAttrs] = useState([]);
	const [currAttr, setCurrentAttr] = useState(null);
	const [message, setMessage] = useState(null);
	const getNextAttr = (attrs) => {
		for(let i = 0; i < attrs.length; i++){
			if(!attrs[i].value || (attrs[i].value && attrs[i].value.trim() === "")) {
				return attrs[i]
			};
		}
		return null;
	};
	const handleSubmit = async () => {
		const _attrs = innerAttrs.map(attr => {
			if(attr.field === currAttr.field){
				attr.value = currAttr.value;
			}
			return attr;
		});
		setInnerAttrs(_attrs);
	};

	useEffect(()=>{
		if(content){
            setInnerAttrs(content.attrs.map(attr => {
				if(!attr.value || (attr.value && attr.value.trim() === "")){
					attr.value = "";
				}
				return attr;
			}));
			if(content?.msg){
				setMessage(content.msg)
			}	
		}
	}, [content]);
	useEffect(()=>{
		if(Array.isArray(innerAttrs) && innerAttrs.length > 0){
			const nextAttr = getNextAttr(innerAttrs);
			if(nextAttr){
				setCurrentAttr(nextAttr);
			}else{
				setCurrentAttr(null);
				handleRequest({
					conversationId: pageProps.conversationId,
					action_query: {
						actionId: action.actionId,
                        flow: action.flow,
						callback: {
                            ticket: {
                                attrs: innerAttrs,
                                priority: get(action, "callback.data.priority", "Low"),
								agent: content.agent,
								segmentId: content.segmentId,
                            }
						}
					}
				});
				setHidden(true)
			}
		}
	}, [innerAttrs]);
	return (
		<>
			{message && (
				<TextMessage 
					content={{
						message: message
					}}
				/>
			)}
			{currAttr && (
				<FormControl
					variant="outlined"
					fullWidth
					size="small"
					sx={{ margin: "10px auto"}}
				>
					<InputLabel
						htmlFor="prompt">
						{currAttr.name}
					</InputLabel>
					<OutlinedInput
						id="prompt"
						onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
						onChange={(e) => { setCurrentAttr({...currAttr, value: e.target.value}); }}
						value={currAttr.value}
                        multiline={currAttr.type === "textarea"}
                        rows={currAttr.type === "textarea" ? 4 : 1}
						autoFocus={true}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									edge="end"
									color="primary"
									disabled={currAttr.value.trim() === ""}
									onClick={handleSubmit}
								>
									<BiSend />
								</IconButton>
							</InputAdornment>
						}
						label="Ask me anything"
						sx={{ 
							backgroundColor: "#FFFFFF",
							borderRadius: "12px"
						}}
					/>
				</FormControl>
			)}
		</>
	);
};

export default Ticket;