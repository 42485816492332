import React, {setState} from "react"
import {
	Box,
	Stack,
	CardHeader,
	IconButton,
    Typography,
} from "@mui/material";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

import AvatarStack from "./../../components/AvatarStack";

const Header = ({page, company, handleClose, mainHeaderBackground, mainHeaderFontColor, homeGreeting, handleNavigate}) => {

    const {location, props} = page;

    const ChatHeader = ({assignedAgents}) => {
        return (
            <CardHeader
                sx={{
                    background: 'white', 
                    padding: "20px", 
                    height: "35px",
                    borderBottom: "1px solid lightgrey",
                }}
                avatar={(
                    <IconButton 
                        aria-label="back" 
                        onClick={()=>{
                            handleNavigate({
                                location: 'home',
                                props: {}
                            });
                        }}>
                        <IoChevronBack />
                    </IconButton>
                )}
                action={
                    <IconButton aria-label="close" onClick={handleClose}>
                        <IoMdClose />
                    </IconButton>
                }
                titleTypographyProps={
                    {
                        color: "white",
                        fontWeight: "600",
                        fontSize: "27px",
                        lineHeight: "32.4px"
                    }
                }
                title={(
                    <Box sx={{}}>
                        <Stack sx={{
                            alignItems: 'center',
                            padding: "10px 0"
                        }}>
                            <AvatarStack
                                {...props}
                                agents={assignedAgents || []}
                                displayAgentInfo={true}
                            />
                        </Stack>
                    </Box>
                )}
            />
        )
    }
    const ConversationsHeader = () => {
        return (
            <CardHeader
                sx={{
                    background: 'white', 
                    padding: "20px", 
                    height: "35px",
                    borderBottom: "1px solid lightgrey",
                }}
                avatar={(
                    <IconButton 
                        aria-label="back"
                        onClick={()=>{
                            handleNavigate({
                                location: 'home',
                                props: {}
                            });
                        }}
                        >
                        <IoChevronBack />
                    </IconButton>
                )}
                action={
                    <IconButton aria-label="close" onClick={handleClose}>
                        <IoMdClose />
                    </IconButton>
                }
                titleTypographyProps={
                    {
                        color: "white",
                        fontWeight: "600",
                        fontSize: "27px",
                        lineHeight: "32.4px"
                    }
                }
                title={(
                    <Typography variant="body1" sx={{textAlign: "center"}}>Conversations</Typography>
                )}
            />
        )
    }
    const ArticlesHeader = () => {
        return (
            <CardHeader
                sx={{
                    background: 'white', 
                    padding: "20px", 
                    height: "35px",
                    borderBottom: "1px solid lightgrey",
                }}
                avatar={(
                    <IconButton 
                        aria-label="back"
                        onClick={()=>{
                            handleNavigate({
                                location: 'home',
                                props: {}
                            });
                        }}>
                        <IoChevronBack />
                    </IconButton>
                )}
                action={
                    <IconButton aria-label="close" onClick={handleClose}>
                        <IoMdClose />
                    </IconButton>
                }
                titleTypographyProps={
                    {
                        color: "white",
                        fontWeight: "600",
                        fontSize: "27px",
                        lineHeight: "32.4px"
                    }
                }
                title={(
                    <Typography variant="body1" sx={{textAlign: "center"}}>FAQs</Typography>
                )}
            />
        )
    }
    const HomeHeader = () => {
        return (
            <CardHeader
                sx={{
                    background: `linear-gradient(45deg, #000000 0%, ${mainHeaderBackground} 100%)`, 
                    padding: "20px", 
                    height: "200px",
                    borderBottom: "none",
                    alignItems: "start"
                }}
                action={
                    <IconButton aria-label="close" onClick={handleClose}>
                        <IoMdClose />
                    </IconButton>
                }
                titleTypographyProps={
                    {
                        color: mainHeaderFontColor,
                        fontWeight: "600",
                        fontSize: "27px",
                        lineHeight: "32.4px"
                    }
                }
                title={
                    <>
                        <Stack direction="column" sx={{justifyContent: "space-between"}}>
                            {company && (
                                <Typography variant="h5">{company}</Typography>
                            )}
                            {homeGreeting && (
                                <Typography variant="h4" sx={{marginTop: "20px"}}>{homeGreeting}</Typography>
                            )}
                        </Stack>
                    </>
                }
            />
        )
    }

    return (
        <>
            {location === 'home' && <HomeHeader {...props} />}
            {location === 'chat' && <ChatHeader {...props}/>}
            {location === 'conversations' && <ConversationsHeader {...props}/>}
            {location === 'articles' && <ArticlesHeader {...props}/>}
        </>
    )
}

export default Header;