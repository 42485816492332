import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Alert from '@mui/material/Alert';

export default function UnVerifiedPage(props){
	const {
		errorMessage
	} = props;
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container sx={{ 
				width: "100%", 
				height: "100vh", 
				backgroundColor: "#FFFFFF" }}>                                      
				<div className="un-verified-container">
					<Alert 
						sx={{maxWidth: '80%'}}
						severity="warning">
                            Unauthorized access, please make sure to add this domain address during activation flow
						{" "}<a href="https://intelswift.com/" target="_blank">IntelSwift</a>
					</Alert>
				</div>
			</Grid>
		</Box>);
};