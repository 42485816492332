import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import { get, cloneDeep } from "lodash";
import {
	Box,
	Stack,
	Card,
	CardContent,
} from "@mui/material";

import eventEmitter from "./../../components/EventEmitter"
import useSettingsSelector from '../../store/selectors/settings';
import Home from "../Home";
import Chat from "../Chat";
import Articles from "../Articles";
import ConversationsList from "../ConversationsList";
import Header from "./header";
import { INTELSWIFT_GRAY_LOGO } from '../../constants';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });

const Landing = (props) => {
	const {botId, tenantId, widget, uuid} = props;
	const {
		HEADER,
		SUB_FOOTER
	} = useSettingsSelector();
	const [page, setPage] = useState({
        location: "home",
        props: {}
    });
	const pageRef = useRef(page);
	const agentsRef = useRef(null);
	const [faq, setFaq] = useState([]);
	const [faqs, setFaqs] = useState([]);
	const [company, setCompany] = useState(null);
	const [contact, setContact] = useState(null);
	const [agents, setAgents] = useState([]);
	const [agentsOnline, setAgentsOnline] = useState([]);
	const [conversations, setConversations] = useState([])
	const HEADER_BACKGROUND =  get(widget, "chat.header.backgroundColor", HEADER.BACKGROUND_COLOR);
	const HEADER_TEXT_COLOR =  get(widget, "chat.header.titleColor", HEADER.FONT_COLOR);
	const TITLE =  get(widget, "chat.header.title", "");
    

	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true },"*");
	};
	
	const fetchConversations = async (uuid, botId, tenantId) => {
		const response = await axiosInstance.get(`/conversations/all/${uuid}_${botId}?botId=${botId}&tenantId=${tenantId}`);
		
		if(response.status === 200){
			const data = get(response, "data.data", []);
            if(Array.isArray(data)){
                setConversations(data.map(conversation => {
					conversation.records = conversation.records.map(el => {
						if(el.reply?.type === "contact_data_collector" || el.reply?.type === 'data_collector' || el.reply?.type === "ticket"){
							el.reply = null;
						}
						return el;
					})
					return conversation;
				}))
                return;
            }
            setConversations([{
                _id: null,
                placeholder: true,
                records: [],
            }])
			
		}
	};
	const fetchContact = async (uuid, botId, tenantId) => {
		const response = await axiosInstance.get(`/contacts/${uuid}_${botId}?botId=${botId}&tenantId=${tenantId}`);
		
		if(response.status === 200){
			const data = get(response, "data.data", null);
            if(data){
                setContact(data)
                return;
            }
		}
	};

	const handleAgents = (message) => {
		try{
			const { agents } = message;
			if(Array.isArray(agents)){
				// Filter agents that have the necessary attributes
				const allAgents = agents.filter(el => el.name && el.profileURL);

				if (!_.isEqual(agentsRef.current, allAgents)) {
					setAgentsOnline(agents.filter(el => el.name && el.profileURL && el.presence === 1))
					setAgents(agents.filter(el => el.name && el.profileURL))
					agentsRef.current = agents.filter(el => el.name && el.profileURL);
				}
			}
		}catch(error){
			console.error("@@ Error: handleAgents @@", error);
		}
	}
    const updateRecord = (record) => {
		if(pageRef.current?.location === 'chat'){
			if(pageRef.current?.props.conversationId === null){
				setPage({
					location: 'chat',
					props: {
						...pageRef.current.props,
						conversationId: record.conversationId
					}
				})
			}
		}
        setConversations(prevConversations => {
			return prevConversations.map(conversation => {
					if(conversation.placeholder && !conversation._id){
						conversation._id = record.conversationId
					}
					if(conversation._id === record.conversationId){
						let match = false;
						conversation.records = conversation.records
							.map(el => {
								if(el.reply?.type === "contact_data_collector" || el.reply?.type === 'data_collector' || el.reply?.type === "ticket"){
									el.reply = null;
								}
								if(el._id === record._id){
									match = true
									el = record;
								}
								return el;
							})
						if(!match){
							conversation.records = [
								...conversation.records,
								{...record}
							]
						}
					}
					return conversation;
				});
		})
    }
	const handleUpdateRecord = (message) => {
		try{
			const { contactId, record } = message;
			if (contactId == `${uuid}_${botId}`) {
                updateRecord(record)
			}
		}catch(error){
			console.error("@@ Error: handleUpdateRecord @@", error);
		}
	}
	const handleContactUpdate = (message) => {
		const {contact} = message;
		setContact(contact);
	}
    const handleConvAssignee = (message) => {
        const { prevConvId, newConvId, assignees } = message;
		const assignedAgents = [];
		if(Array.isArray(assignees)){
			assignees.forEach(assignee => {
				const agent = agentsRef.current.find(el => el.agentIDaasId === assignee);
				if(agent){
					assignedAgents.push(agent)
				}
			})
		}
        setPage(prevState => {
            if(prevState.location === 'chat' && prevState.props.conversationId === prevConvId){
				return {
                    location: 'chat',
                    props: {
                        ...prevState.props,
                        conversationId: newConvId,
                        assignedAgents: assignedAgents,
                    }
                }
            }
            return prevState
        })
		fetchConversations(uuid, botId, tenantId)
    }

	useEffect(()=>{
		pageRef.current = page;
	}, [page]);

    useEffect(() => {
		if (uuid && widget && tenantId && botId) {
			fetchContact(uuid, botId, tenantId)
			fetchConversations(uuid, botId, tenantId);
		}
	}, [uuid, widget, tenantId, botId]);

	useEffect(()=>{
		if(widget){
			setFaqs(widget.faqs);
			setCompany(get(widget, "chat.header.company", null))
		}
	}, [widget]);
	useEffect(() => {
        eventEmitter.on('agents_update', handleAgents);
		eventEmitter.on('record_update', handleUpdateRecord);
		eventEmitter.on('contact_update', handleContactUpdate);
        eventEmitter.on('conversation_assignee', handleConvAssignee)
        // Cleanup listener on unmount
        return () => {
          eventEmitter.off('agents_update', handleAgents);
		  eventEmitter.off('record_update', handleUpdateRecord);
		  eventEmitter.off('contact_update', handleContactUpdate);
          eventEmitter.off('conversation_assignee', handleConvAssignee)
        };
    }, []);

    return (
		<>
			<Box sx={{ width: '100%', }} id="wws">
				<Card className="widget-root">
                    <Header 
                        page={page}
						company={company}
                        agents={agents}
                        mainHeaderBackground={HEADER_BACKGROUND}
						mainHeaderFontColor={HEADER_TEXT_COLOR}
                        homeGreeting={TITLE}
                        handleClose={hideWidget}
						handleNavigate={(props)=>{
							setPage(props)}
						}
                    />
					<CardContent
						className = {page?.location === "home" ? "home-content" : "chat-content"}
						sx={{
							overflowY: 'auto',
							position: 'relative',
							padding: '0 !important',
							overflowY: 'scroll',
							marginTop: page?.location === "home" ? "-80px" : "0"
						}}>
						{page?.location === "articles" && (
							<>
								<Articles
									{...page.props}
									handleNavigate={setPage}
								/>
							</>
						)}
						{page?.location === "home" && Array.isArray(conversations) && conversations.length > 0 && (
							<>
								<Home
									{...props}
									faqs={faqs}
									contact={contact}
									agents={agents}
									agentsOnline={agentsOnline}
									conversations={conversations}
									handleNavigate={setPage}
								/>
							</>
						)}
						{page?.location === "chat" && (
							<>
								<Chat
									{...props}
									agents={agents}
                                    contact={contact}
									faq={faq}
                                    conversations={conversations}
                                    pageProps={page.props}
                                    handleNavigate={setPage}
									setFaq={setFaq}
								/>
							</>
						)}
						{page?.location === "conversations" && (
							<>
								<ConversationsList
									{...props}
									agents={agents}
                                    conversations={conversations}
                                    handleNavigate={setPage}
								/>
							</>
						)}
					</CardContent>
					<Stack direction="column">
						<Box sx={{
							bgcolor: SUB_FOOTER.BACKGROUND_COLOR,
							padding: '16px !important',
						}}>
							<div style={{
								display: 'flex',
								gap: '10px',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
								{INTELSWIFT_GRAY_LOGO}
								<span style={{color: 'black'}}>Powered by <a href="https://intelswift.com/" style={{color: 'inherit'}} target='_blank' rel="noreferrer">IntelSwift</a></span>
							</div>
						</Box>
					</Stack>
				</Card>
			</Box>
		</>
	);
};

export default Landing;