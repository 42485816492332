import React, {memo} from "react";
import {
	Stack,
} from '@mui/material';

import RecentConversations from "./RecentConversations";
import SendNewMessage from "./SendNewMessage";
import Conversations from "./Conversations";
import Help from "./Help";

const Home = (props) => {
	const { faqs, handleNavigate, agents, agentsOnline, conversations, widget } = props;
    const placeholderConv = Array.isArray(conversations) ? conversations.find(conv => conv.placeholder === true) : null;

	return (
		<Stack direction="column" spacing={1} sx={{minHeight: "274px", height: "100%", padding: "10px"}}>
			{Array.isArray(faqs) && faqs.length > 0 && (
				<Help
					articles={faqs}
					meta={widget.meta}
					conversationId={placeholderConv._id}
					handleNavigate={handleNavigate}
				/>
			)}
			{Array.isArray(agents) && agents.length > 0 && Array.isArray(conversations) && conversations.length > 1 && (
				<Conversations
					meta={widget.meta}
					handleNavigate={handleNavigate}
				/>
			)}
			{Array.isArray(agents) && agents.length > 0 && (
				<RecentConversations
					agents={agents}
					meta={widget.meta}
					conversations={conversations}
					handleNavigate={handleNavigate}
				/>
			)}
			<SendNewMessage 
				agents={agentsOnline}
				meta={widget.meta}
				conversation={placeholderConv}
				handleNavigate={handleNavigate}
			/>
		</Stack>
	);
};

export default memo(Home);