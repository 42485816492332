import React from "react";
import {Box, Typography} from "@mui/material";
import useSettingsSelector from "../../store/selectors/settings";

export default function Sender(props) {
	let ReplyContent = null;
	
	const { content } = props;
	const { type } = content;
	const { SENDER } = useSettingsSelector();

	switch (type) {
		case 'msg':
			ReplyContent = (
				<>
					{content.message && (
						<Typography variant="body1" sx={{ margin: "0px", color: SENDER.FONT_COLOR }}>
								{content.message}
						</Typography>
					)}
				</>
			);
			break;  
		default:
			console.warn(`Unrecognized type ${type} of sender content.`);
	}
	return (
		<Box className="sender-ui" sx={{margin: 0}}>
			<div
				className="sender-message-container"
				style={{ backgroundColor: SENDER.BACKGROUND_COLOR }}>
				{ReplyContent}
			</div>
		</Box>
	);
}
