import { createContext, useState } from "react";

const initialState = {
	isAuthLoading: false,
	error: undefined,

};
const AuthContext = createContext(initialState);

const AuthProvider = (props) => {
	const {
		children
	} = props;

	const [isAuthLoading, setIsAuthLoading] = useState(false);
	const [error, setError] = useState(undefined);

	const value = {
		isAuthLoading,
		error
	};
	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

export {
	AuthContext,
	AuthProvider
};