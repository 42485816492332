import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Box,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemText,
} from "@mui/material"
const Conversations = ({handleNavigate, meta}) => {
    const convLocale = meta?.locale ? meta.locale.find(el => el.field === "conversations") : null

    return (
        <Box>
            <List sx={{
                borderRadius: '10px',
                background: "white",
                paddingTop: "0px",
                paddingBottom: "0px",
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            }}>
                <Stack>
                    <ListItemButton
                        onClick={() => {
                            handleNavigate({
                                location: 'conversations',
                                props: {}
                            });
                        }} >
                        <ListItemText>
                            <Typography variant="body1">
                                {convLocale && convLocale.name ? convLocale.name : "Conversations"}
                            </Typography>
                        </ListItemText>
                        <KeyboardArrowRightIcon />
                    </ListItemButton>
                </Stack>
            </List>
        </Box>
    )
}

export default Conversations;