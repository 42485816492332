import React, {useState, useEffect} from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Box,
    Stack,
    Typography,
    List,
    ListItemButton,
	ListItemText,
} from "@mui/material"
import AvatarStack from "./../../components/AvatarStack";
import {formatTimeAgo, trimString} from "./../../utils/common"

const RecentConversations = ({conversations, agents, handleNavigate, meta}) => {
    const recentConvLocale = meta?.locale ? meta.locale.find(el => el.field === "recent_conversations") : null;

    const [recentConv, setRecentConv] = useState([])

    const getTimeAgo = (timeStr) => {
        try{
            return formatTimeAgo(timeStr)
        }catch(error){
            console.error("@@ Error: getTimeAgo @@", error)
            return null;
        }
    }
    useEffect(()=>{
        if(Array.isArray(conversations) && Array.isArray(agents)){
            const latestConversation = conversations
            .filter(conv => conv.placeholder !== true)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort descending by createdAt
            .slice(0, 1); // top 3 conversations
            const recentConversations = latestConversation.map(conv => {
                const assignedAgents = [];
                const lastRecord = conv.records[conv.records.length - 1]
                conv.assignees.forEach(agentIDaasId => {
                    
                    const agent = agents.find(el => {
                        return el.agentIDaasId == agentIDaasId
                    });
                    assignedAgents.push(agent)
                })
                return {
                    conversationId: conv._id,
                    assignees: conv.assignees,
                    agents: assignedAgents,
                    lastMsg: trimString(lastRecord?.reply?.message || lastRecord?.query?.message, 35),
                    time: getTimeAgo(lastRecord?.updatedAt),
                }
            })
            setRecentConv(recentConversations)
        }
        
    }, [conversations, agents])

    return (
        <>
            {Array.isArray(recentConv) && recentConv.length > 0 && (
                <Box>
                    <List sx={{
                        borderRadius: '10px',
                        background: "white",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
                    }}>
                        <Typography variant="body1" sx={{padding: "10px 0 0 15px", fontWeight: "bold"}}>{recentConvLocale && recentConvLocale.name ? recentConvLocale.name : "Recent conversations"}</Typography>
                        {recentConv.map((conv, idx) => {
                            return (
                                <Stack key={`recent-conversation-${idx}`}>
                                    <ListItemButton
                                        key={`prev-converstion-${idx}`}
                                        onClick={() => {
                                            handleNavigate({
                                                location: 'chat',
                                                props: {
                                                    conversationId: conv.conversationId,
                                                    assignedAgents: conv.agents,
                                                    displayAgentInfo: true
                                                }
                                                
                                            });
                                        }} >
                                        <ListItemText>
                                            <Stack direction="row" spacing={1}>
                                                <AvatarStack
                                                    agents={conv.agents || []}
                                                />
                                                <Stack direction="column" spacing={0}>
                                                    <Typography variant="body1">
                                                        {conv.lastMsg}
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        {conv.agents.length === 1 && (
                                                            <Typography 
                                                                variant="body1"
                                                                sx={{color: "grey"}}
                                                            >
                                                                {conv.agents.length === 1 && (
                                                                    <>{conv.agents[0].name}</>
                                                                )}
                                                            </Typography>
                                                        )}
                                                        <Typography 
                                                                variant="body1"
                                                                sx={{color: "grey"}}
                                                            >
                                                                {conv.time && (
                                                                    <> {conv.time}</>
                                                                )}
                                                            </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </ListItemText>
                                        <KeyboardArrowRightIcon />
                                    </ListItemButton>
                                </Stack>
                            )
                        })}
                    </List>
                </Box>
            )}
        </>
    )
}

export default RecentConversations;