import React, {useEffect, useState, memo} from "react";
import { BiSend } from "react-icons/bi";
import { 
	IconButton,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
} from "@mui/material";
import TextMessage from "./TextMessage"

const ContactDataCollector = (props) => {
	const {contact, content, query, pageProps, handleRequest, setLastQuery, setHidden} = props;
	const [innerAttrs, setInnerAttrs] = useState([]);
	const [prevQuery, setPrevQuery] = useState("");
	const [currAttr, setCurrentAttr] = useState(null);
	const [processed, setProcessed] = useState(false);
	const [message, setMessage] = useState(null);

	const isStart = content?.config?.start;
	const getNextAttr = (attrs) => {
		for(let i = 0; i < attrs.length; i++){
			if(!attrs[i].value) {
				return attrs[i]
			};
		}
		return null;
	};
	const handleSubmit = async () => {
		const _attrs = innerAttrs.map(attr => {
			if(attr.field === currAttr.field){
				attr.value = currAttr.value;
			}
			return attr;
		});
		setInnerAttrs(_attrs);
	};
	useEffect(()=>{
		if(contact && content){
			const data = content.config.attrs.map(attr => {
				attr.value = "";
				
				if(contact.meta[attr.field]){
					attr.value = contact.meta[attr.field];
				}
				return attr;
			})
			setInnerAttrs(data);
			if(content.config?.msg){
				setMessage(content.config.msg)
			}
		}
	}, [contact, content]);

	useEffect(()=>{
		setPrevQuery(query)
	}, [query])
	
	useEffect(()=>{
		if(Array.isArray(innerAttrs) && innerAttrs.length > 0){
			const nextAttr = getNextAttr(innerAttrs);
			if(nextAttr){
				setCurrentAttr(nextAttr);
			}else{
				setCurrentAttr(null);
				if(!processed){
					handleRequest({
						conversationId: pageProps.conversationId,
						action_query: {
							actionId: content.actionId,
							flow: content.flow,
							prevQuery: (isStart && content.flow === "user_sends_a_msg") ? prevQuery : "",
							callback: {
								contact: {
									attrs: innerAttrs,
								}
							}
						}
					});
					setLastQuery("")
					setPrevQuery("")
					setProcessed(true)
					setHidden(true)
				}
			}
		}
	}, [innerAttrs]);
	return (
		<>
			{message && (
				<TextMessage 
					content={{
						message: message
					}}
				/>
			)}
			{currAttr && (
				<FormControl
					variant="outlined"
					fullWidth
					size="small"
					sx={{ margin: "10px auto"}}
				>
					<InputLabel
						htmlFor="prompt">
						{currAttr.name}
					</InputLabel>
					<OutlinedInput
						id="prompt"
						onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
						onChange={(e) => { setCurrentAttr({...currAttr, value: e.target.value}); }}
						value={currAttr.value}
						multiline={currAttr.type === "textarea"}
						rows={currAttr.type === "textarea" ? 4 : 1}
						autoFocus={true}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									edge="end"
									color="primary"
									disabled={currAttr.value.trim() === ""}
									onClick={handleSubmit}
								>
									<BiSend />
								</IconButton>
							</InputAdornment>
						}
						label="Ask me anything"
						sx={{ 
							backgroundColor: "#FFFFFF",
							borderRadius: "12px"
						}}
					/>
				</FormControl>
			)}
		</>
	);
};

export default memo(ContactDataCollector);