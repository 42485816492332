import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
	Box,
	Stack,
    Typography,
    ListItemText,
    ListItemButton,
    List,

} from '@mui/material';
import AvatarStack from "./../../components/AvatarStack";

const SendNewMessage = ({agents, handleNavigate, conversation, meta}) => {
    const startConvLocale = meta?.locale ? meta.locale.find(el => el.field === "start_new_conversation") : null;
    
    return (
        <Box>
            <List sx={{
                borderRadius: '10px',
                background: "white",
                paddingTop: "0px",
                paddingBottom: "0px",
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            }}>
                <ListItemButton
                    onClick={()=>{
                        handleNavigate({
                            location: 'chat',
                            props: {
                                conversationId: conversation ? conversation._id : null,
                                agentIds: []
                            } 
                        });
                    }} 
                >
                    <ListItemText>
                        <Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
                            {Array.isArray(agents) && agents.length > 0 && (
                                <Stack direction="row">
                                    <AvatarStack
                                        agents={agents}
                                    />
                                </Stack>
                            )}
                            <Typography variant="body1">{startConvLocale && startConvLocale.name ? startConvLocale.name : "Start new conversation"}</Typography>
                        </Stack>
                    </ListItemText>
                    <KeyboardArrowRightIcon />
                </ListItemButton>
            </List>
        </Box>
    )
}

export default SendNewMessage;