import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Box,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemText,
} from "@mui/material"
const Help = ({articles, conversationId, handleNavigate, meta}) => {
    const searchLocale = meta?.locale ? meta.locale.find(el => el.field === "search") : null

    return (
        <Box>
            <List sx={{
                borderRadius: '10px',
                background: "white",
                paddingTop: "0px",
                paddingBottom: "0px",
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            }}>
                <Stack>
                    <ListItemButton
                        onClick={() => {
                            handleNavigate({
                                location: 'articles',
                                props: {
                                    articles: articles,
                                    conversationId: conversationId
                                }
                            });
                        }} >
                        <ListItemText>
                            <Typography variant="body1">
                                {searchLocale && searchLocale.name ? searchLocale.name : "Search"} FAQs
                            </Typography>
                        </ListItemText>
                        <KeyboardArrowRightIcon />
                    </ListItemButton>
                </Stack>
            </List>
        </Box>
    )
}

export default Help;