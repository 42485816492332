import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import "./index.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 5,
	borderRadius: 0,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: "rgba(141, 43, 255, 1)", 
	},
}));

const ProgressBar = () => {
	return (
		<Box sx={{ width: "100%" }} className="progressBarClass">
			<BorderLinearProgress />
		</Box>
	);
};

export default ProgressBar;