import React, {useState} from "react";
import { BrowserRouter } from "react-router-dom";
import { SettingsContextProvider } from "./store/settings";
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from "./store/auth";

import {DefaultTheme} from "./assets/theme";
import ApplicationRoutes from "./routes";

import "./App.css"



export default function App() {
	
	const [tenantId, setTenantId] = useState(null);
	const [botId, setBotId] = useState(null);
	const [host, setHost] = useState(null);
	const [lang, setLang] = useState(null);

	const propsInterval = setInterval(widgetTimer, 2000);

	function widgetTimer() {
		const tenantIDFromStorage = localStorage.getItem("wws-tenant-id");
		const botIDFromStorage = localStorage.getItem("wws-bot-id");
		const hostURLFromStorage = localStorage.getItem("wws-host");
		const contactLangFromStorage = localStorage.getItem("wws-contact-language");

		if((tenantIDFromStorage && tenantIDFromStorage != "undefined") && (botIDFromStorage && botIDFromStorage != "undefined") && (hostURLFromStorage && hostURLFromStorage != "undefined") && (contactLangFromStorage && contactLangFromStorage != "undefined")){
			clearInterval(propsInterval);
			setTenantId(tenantIDFromStorage);
			setBotId(botIDFromStorage);
			setHost(hostURLFromStorage);
			setLang(contactLangFromStorage)
		}
	}

	return (
		<>
			{tenantId && botId && host && lang && (
				<AuthProvider>
					<ThemeProvider theme={DefaultTheme}>
						<SettingsContextProvider>
							<BrowserRouter>
								<ApplicationRoutes 
									tenantId={tenantId} 
									botId={botId} 
									host={host} 
									lang={lang}
								/>
							</BrowserRouter>
						</SettingsContextProvider>
					</ThemeProvider>
				</AuthProvider>
			)}
		</>
	);
}