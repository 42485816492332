import { useContext } from "react";
import { SettingsContext } from "../settings";

const useSettingsSelector = () => {
	const settings = useContext(SettingsContext);
	return {
		...settings
	};
};

export default useSettingsSelector;