import React from "react";
import Linkify from "linkify-react";
import { Link } from 'react-router-dom';
import { Box } from "@mui/material";
import useSettingsSelector from "./../../store/selectors/settings";


const Message = ({content}) => {
	const { RECIEVER } = useSettingsSelector();
	const {message} = content;

	const renderLink = ({ attributes, content }) => {
		const { href, ...props } = attributes;
		return <Link to={href} target="_blank" {...props}>{content}</Link>;
	};

	return (
		<Box sx={{
			margin: 0, 
			backgroundColor: RECIEVER.BACKGROUND_COLOR, 
			padding: "1rem", 
			borderTopRightRadius: "12px",
			borderBottomRightRadius: "12px",
			borderBottomLeftRadius: "12px"
		}}>
			<Linkify options={{ render: renderLink }}>
				{message}
			</Linkify>
		</Box>
	);
};

export default Message;