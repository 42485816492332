import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Box,
    Stack,
    Typography,
    List,
    ListItemButton,
    ListItemText,

} from "@mui/material"

const Articles = ({articles, conversationId, handleNavigate}) => {
    return (
        <Box>
            <List sx={{
                borderRadius: '10px',
                margin: '15px 10px 10px 10px',
                background: "white",
                paddingTop: "0px",
                paddingBottom: "0px",
            }}>
                {Array.isArray(articles) && articles.map((option, index) => {
                    return (
                        <ListItemButton
                            key={index}
                            onClick={() => {
                                handleNavigate({
                                    location: "chat",
                                    props: {
                                        conversationId: conversationId,
                                        article: option.question
                                    }
                                })
                            }} >
                            <ListItemText>
                                {option.question}
                            </ListItemText>
                            <KeyboardArrowRightIcon />
                        </ListItemButton>);
                })}
            </List>
        </Box>
    )
}

export default Articles;